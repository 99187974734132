import { Button, Input } from "@nextui-org/react";
import { AuthContextUserProps } from "../../../contexts/auth.context";

// NameInput.tsx - Fixed typing
interface NameInputProps {
  name: string;
  setName: (name: string) => void;
  updateUser: (data: Partial<AuthContextUserProps>) => void;
  isLoading: boolean;
  currentName: string;
}

export const NameInput: React.FC<NameInputProps> = ({
  name,
  setName,
  updateUser,
  isLoading,
  currentName,
}) => (
  <div className="w-full flex justify-between items-center gap-1">
    <Input
      size="lg"
      radius="lg"
      variant="faded"
      value={name}
      onValueChange={setName}
      placeholder="Digite o seu Nome"
      classNames={{ inputWrapper: "h-12" }}
      className="flex-grow"
    />
    <Button
      color="primary"
      className="h-12"
      radius="lg"
      onPress={() => updateUser({ name })}
      isDisabled={name === currentName || name === ""}
      isLoading={isLoading}
    >
      Confirmar
    </Button>
  </div>
);
