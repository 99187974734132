import { Button } from "@nextui-org/react";
import { ReactNode } from "react";
import { GradientIcon } from "../../Atoms/GradientIcon";

interface AuthFormProps {
  title: string;
  subtitle: string;
  children: ReactNode;
  onSubmit: (e: React.FormEvent) => void;
  isFormValid: boolean;
  isLoading: boolean;
  submitText: string;
  links?: ReactNode;
}

export const AuthForm: React.FC<AuthFormProps> = ({
  title,
  subtitle,
  children,
  onSubmit,
  isFormValid,
  isLoading,
  submitText,
  links,
}) => (
  <main className="flex items-center justify-start min-h-svh flex-col gap-8 px-6 py-16 w-full">
    <GradientIcon className="w-12" />
    <div className="flex flex-col gap-8 max-md:w-full">
      <div className="flex flex-col justify-center items-center gap-1.5">
        <h2 className="font-bold text-2xl text-center text-neutral-900">
          {title}
        </h2>
        <p className="text-base text-center text-neutral-700">{subtitle}</p>
      </div>
      <form onSubmit={onSubmit} className="flex flex-col gap-4">
        {children}
        <div className="flex flex-col gap-2">
          <Button
            color="primary"
            className="font-medium w-full h-12"
            isDisabled={!isFormValid}
            isLoading={isLoading}
            size="lg"
            radius="lg"
            type="submit"
          >
            {submitText}
          </Button>
          {links}
        </div>
      </form>
    </div>
  </main>
);
