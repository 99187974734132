import { useState, useEffect } from "react";
import { notificationService } from "../services/notifications/NotificationService";

export const useNotificationPermission = () => {
  const [permissionState, setPermissionState] =
    useState<NotificationPermission>("default");
  const [isLoading, setIsLoading] = useState(true);

  const checkPermission = () => {
    setIsLoading(true);
    try {
      if (!("Notification" in window)) {
        setPermissionState("denied");
      } else {
        setPermissionState(Notification.permission);
      }
    } catch (error) {
      console.error("Error checking notification permission:", error);
      setPermissionState("denied");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    checkPermission();
  }, []);

  const requestPermission = async () => {
    setIsLoading(true);
    try {
      if (!("Notification" in window)) {
        setPermissionState("denied");
        return "denied";
      }
      const permission = await Notification.requestPermission();
      setPermissionState(permission);
      return permission;
    } catch (error) {
      console.error("Error requesting notification permission:", error);
      setPermissionState("denied");
      return "denied";
    } finally {
      setIsLoading(false);
    }
  };

  const isPWA = notificationService.isPWA();

  return {
    permissionState,
    isLoading,
    requestPermission,
    checkPermission,
    isPWA,
  };
};
