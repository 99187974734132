import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  RadioGroup,
  Radio,
  useDisclosure,
  Textarea,
} from "@nextui-org/react";
import { motion, AnimatePresence } from "framer-motion";

interface Objective {
  key: string;
  value: string;
}

interface ObjectiveSelectorProps {
  objectives: Objective[];
  onObjectiveSelect: (objective: string) => void;
}

const contentVariants = {
  hidden: { opacity: 0, height: 0 },
  visible: {
    opacity: 1,
    height: "auto",
    transition: {
      opacity: { duration: 0.2 },
      height: { duration: 0.2, type: "spring", stiffness: 300, damping: 30 },
    },
  },
  exit: {
    opacity: 0,
    height: 0,
    transition: {
      opacity: { duration: 0.1 },
      height: { duration: 0.1, type: "spring", stiffness: 300, damping: 30 },
    },
  },
};

const spanVariants = {
  hidden: { opacity: 0, y: -10 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      opacity: { duration: 0.3 },
      y: { duration: 0.3, type: "spring", stiffness: 300, damping: 30 },
    },
  },
  exit: {
    opacity: 0,
    y: -10,
    transition: {
      opacity: { duration: 0.2 },
      y: { duration: 0.2 },
    },
  },
};

export const ObjectiveSelector: React.FC<ObjectiveSelectorProps> = ({
  objectives,
  onObjectiveSelect,
}) => {
  const [selectedObjective, setSelectedObjective] = useState<string | null>(
    null
  );
  const [tempSelectedObjective, setTempSelectedObjective] = useState<
    string | null
  >(null);
  const [customObjective, setCustomObjective] = useState<string>("");
  const [tempCustomObjective, setTempCustomObjective] = useState<string>("");
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const handleObjectiveChange = (value: string) => {
    if (value === "Outro") {
      setTempSelectedObjective("Outro");
    } else {
      setTempSelectedObjective(value);
      setTempCustomObjective("");
    }
  };

  const handleCustomObjectiveChange = (value: string) => {
    setTempCustomObjective(value);
  };

  const confirmObjective = () => {
    if (tempSelectedObjective === "Outro") {
      setSelectedObjective("Outro");
      setCustomObjective(tempCustomObjective);
      onObjectiveSelect(tempCustomObjective);
    } else if (tempSelectedObjective) {
      setSelectedObjective(tempSelectedObjective);
      setCustomObjective("");
      onObjectiveSelect(tempSelectedObjective);
    }
    onOpenChange();
  };

  const handleOpenChange = (open: boolean) => {
    if (open) {
      setTempSelectedObjective(selectedObjective);
      setTempCustomObjective(customObjective);
    }
    onOpenChange();
  };

  return (
    <div>
      <Button
        onPress={onOpen}
        variant="solid"
        color="primary"
        radius="md"
        size="lg"
        className="font-medium w-full"
      >
        Selecione o objetivo
      </Button>
      <AnimatePresence>
        {selectedObjective && (
          <motion.span
            key="selected-objective"
            variants={spanVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            className="text-sm text-default-500 px-0.5 block mt-2"
          >
            Objetivo selecionado: {selectedObjective}
          </motion.span>
        )}
      </AnimatePresence>

      <Modal
        backdrop="blur"
        isOpen={isOpen}
        onOpenChange={handleOpenChange}
        placement="top-center"
        classNames={{
          backdrop: "bg-black/75",
        }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Objetivo do Agendamento
              </ModalHeader>
              <ModalBody>
                <motion.div
                  className="flex flex-col gap-2"
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                >
                  <RadioGroup
                    orientation="vertical"
                    value={tempSelectedObjective}
                    onValueChange={handleObjectiveChange}
                    className="px-0.5"
                    classNames={{ label: "text-default-900 font-semibold" }}
                  >
                    {objectives.map((objective) => (
                      <Radio key={objective.key} value={objective.key}>
                        {objective.value}
                      </Radio>
                    ))}
                    <Radio value="Outro">Outro:</Radio>
                  </RadioGroup>
                  <AnimatePresence>
                    {tempSelectedObjective === "Outro" && (
                      <motion.div
                        variants={contentVariants}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                      >
                        <Textarea
                          minRows={2}
                          size="lg"
                          variant="bordered"
                          value={tempCustomObjective}
                          onValueChange={handleCustomObjectiveChange}
                          label="Qual o objetivo do agendamento?"
                        />
                      </motion.div>
                    )}
                  </AnimatePresence>
                </motion.div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Cancelar
                </Button>
                <Button
                  color="primary"
                  onPress={confirmObjective}
                  isDisabled={
                    tempSelectedObjective === "Outro" && !tempCustomObjective
                  }
                >
                  Confirmar
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
};
