import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { State } from "../../models/State";
import { City } from "../../models/City";

const fetchCities = async (stateId: string): Promise<City[]> => {
  const { data } = await axios.get<City[]>(
    `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateId}/municipios`
  );
  return data.map(({ id, nome }) => ({ id, nome }));
};

export const useIBGECitiesQuery = (selectedState: State | undefined) => {
  return useQuery<City[], Error>({
    queryKey: ["cities", selectedState?.id],
    queryFn: () => fetchCities(selectedState?.id as string),
    enabled: !!selectedState?.id,
    staleTime: 30 * 24 * 60 * 60 * 1000,
  });
};
