import React from "react";
import { ProfileHeader } from "../../Organisms/ProfileHeader";
import { AuthContextUserProps } from "../../../contexts/auth.context";
import { VerificationSection } from "../../Organisms/VerificationSection";
import { Button } from "@nextui-org/react";
import { User } from "firebase/auth";
import { PreferencesSection } from "../../Organisms/PreferencesSection";
import { LogOut } from "lucide-react";

interface ProfileTemplateProps {
  user: User;
  currentUser: AuthContextUserProps;
  name: string;
  setName: (name: string) => void;
  updateUser: (data: Partial<AuthContextUserProps>) => void;
  isUpdateUserLoading: boolean;
  handleEmailVerification: () => void;
  isEmailVerificationLoading: boolean;
  onOpenPhoneVerification: () => void;
  handleSignOut: () => void;
  // Add props for PreferencesSection
  cityItems: { key: string; label: string }[];
  isLoadingStates: boolean;
  isLoadingCities: boolean;
  handleStateChange: (stateId: string) => void;
  handleCityChange: (cityId: string) => void;
  selectedState?: { id: string };
  selectedCity?: { id: string };
  stateItems: { key: string; label: string }[];
  isManualLocationMutationLoading: boolean;
  handleLocationUpdate: () => void;
  timezone: string;
  handleTimezoneChange: (value: string) => void;
  isTimezoneDisabled: boolean;
  isNotificationEnabled: boolean;
  handleNotificationToggle: () => void;
}

export const ProfileTemplate: React.FC<ProfileTemplateProps> = (props) => (
  <main className="flex flex-col justify-start items-center gap-8 p-4 min-h-svh pb-20 w-full">
    <ProfileHeader
      name={props.currentUser.name as string}
      photoURL={props.currentUser.photoURL as string}
      displayName={props.user.displayName as string}
      email={props.currentUser.email as string}
      isEmailVerified={props.currentUser.emailVerified as boolean}
      isPhoneVerified={props.currentUser.phoneConfirmed as boolean}
    />
    <div className="flex flex-col gap-8 w-full md:max-w-md">
      <VerificationSection
        name={props.name}
        setName={props.setName}
        updateUser={props.updateUser}
        isUpdateUserLoading={props.isUpdateUserLoading}
        currentName={props.currentUser.name as string}
        email={props.currentUser.email as string}
        isEmailVerified={props.currentUser.emailVerified}
        phoneNumber={props.currentUser.phoneNumber as string}
        isPhoneVerified={props.currentUser.phoneConfirmed as boolean}
        handleEmailVerification={props.handleEmailVerification}
        isEmailVerificationLoading={props.isEmailVerificationLoading}
        onOpenPhoneVerification={props.onOpenPhoneVerification}
      />
      <PreferencesSection
        cityItems={props.cityItems}
        handleCityChange={props.handleCityChange}
        handleLocationUpdate={props.handleLocationUpdate}
        handleNotificationToggle={props.handleNotificationToggle}
        handleStateChange={props.handleStateChange}
        handleTimezoneChange={props.handleTimezoneChange}
        isLoadingCities={props.isLoadingCities}
        isLoadingStates={props.isLoadingStates}
        isManualLocationMutationLoading={props.isManualLocationMutationLoading}
        isNotificationEnabled={props.isNotificationEnabled}
        isTimezoneDisabled={props.isTimezoneDisabled}
        stateItems={props.stateItems}
        timezone={props.timezone}
        updateUser={props.updateUser}
        user={props.currentUser}
        selectedCity={props.selectedCity}
        selectedState={props.selectedState}
      />

      <Button
        size="lg"
        variant="flat"
        color="danger"
        onPress={props.handleSignOut}
        startContent={<LogOut />}
        className="font-medium w-full"
      >
        Sair da Conta
      </Button>
    </div>
  </main>
);
