import { DateUtils } from "./dateUtils";

export const getFormattedAppointmentOption = () => {
  const formatDate = (dateOption: string | null, timezone?: string) => {
    if (!dateOption) return { formattedDate: "", formattedTime: "" };

    const date = DateUtils.fromDBFormat(dateOption, timezone);

    if (!date) {
      console.warn("Invalid date format:", dateOption, timezone);
      return { formattedDate: "Data inválida", formattedTime: "" };
    }

    const { formattedDate, formattedTime } = DateUtils.getFormattedDateAndTime(
      date,
      timezone
    );

    return {
      formattedDate,
      formattedTime,
      fullDate: DateUtils.toDisplayFormat(date, timezone),
    };
  };

  return { formatDate };
};
