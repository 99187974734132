import React from "react";
import { Select, SelectItem } from "@nextui-org/react";
import { Schedule } from "../../../models/Schedule";

interface ServiceSelectionProps {
  selectedService: string | null;
  services: Schedule["services"];
  onServiceChange: (serviceId: string | null) => void;
}

export const ServiceSelection: React.FC<ServiceSelectionProps> = ({
  selectedService,
  services,
  onServiceChange,
}) => {
  return (
    <div className="flex flex-col w-full gap-2 bg-white rounded-large">
      <div className="w-full bg-gray-100 rounded-medium p-2">
        <h2 className="text-gray-700 font-semibold text-sm">Serviço</h2>
      </div>
      {services && Object.keys(services).length > 0 ? (
        <Select
          size="lg"
          radius="md"
          variant="bordered"
          label="Selecione um serviço"
          selectedKeys={selectedService ? [selectedService] : []}
          onSelectionChange={(keys) => {
            const selected = Array.from(keys as Set<string>)[0] || null;
            onServiceChange(selected);
          }}
          classNames={{
            trigger: "h-12",
            value: "text-base",
            listbox: "min-w-[240px]",
          }}
          renderValue={(items) => {
            const selected = items[0];
            if (!selected || !services[selected.key as string]) return null;
            const service = services[selected.key as string];
            return (
              <div className="flex justify-between items-center gap-2">
                <span>{service.name}</span>
                {service.price && (
                  <span className="text-success-600 font-semibold">
                    {service.price}
                  </span>
                )}
              </div>
            );
          }}
        >
          {Object.entries(services).map(([serviceId, serviceDetails]) => (
            <SelectItem
              key={serviceId}
              textValue={serviceDetails.name || ""}
              color="secondary"
              variant="flat"
            >
              <div className="flex justify-between items-center">
                <span>{serviceDetails.name}</span>
                {serviceDetails.price && (
                  <span className="text-success-600 font-semibold">
                    {serviceDetails.price}
                  </span>
                )}
              </div>
            </SelectItem>
          ))}
        </Select>
      ) : (
        <p className="text-sm text-danger-600">
          Nenhum serviço disponível no momento.
        </p>
      )}
    </div>
  );
};
