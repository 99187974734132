import { Appointment } from "../models/Appointment";
import { DateUtils } from "./dateUtils";

export const getFormattedAppointmentDate = (
  appointment: Appointment,
  timezone?: string
) => {
  const getValidDate = (dateOption: string | null | undefined) => {
    return DateUtils.fromDBFormat(dateOption, timezone);
  };

  const appointmentDate =
    getValidDate(appointment.selectedOption) ||
    getValidDate(appointment.option1) ||
    getValidDate(appointment.option2) ||
    getValidDate(appointment.option3) ||
    DateUtils.now(); // fallback to current date if no valid options

  return DateUtils.getFormattedDateAndTime(appointmentDate, timezone);
};
