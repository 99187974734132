import { useInfiniteQuery } from "@tanstack/react-query";
import { Appointment, AppointmentStatus } from "../../models/Appointment";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  Query,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { fireStoreDb } from "../../services/firebase/firebase";
import { CalendarDate } from "@internationalized/date";
import { useAuth } from "../../contexts/auth.context";
import { useUserInformationQuery } from "./userInformationQuery";
import { DateUtils } from "../../utils/dateUtils";

const APPOINTMENTS_PER_PAGE = 10;

export const useMyAppointments = (
  status?: AppointmentStatus,
  dateRange?: { start: CalendarDate; end: CalendarDate },
  appointmentTypes?: string[]
) => {
  const { currentUser } = useAuth();
  const userInformationQuery = useUserInformationQuery();

  return useInfiniteQuery<Appointment[]>({
    queryKey: [
      "appointments",
      currentUser?.uid,
      userInformationQuery.data?.providerId,
      status,
      dateRange,
      appointmentTypes,
    ],
    queryFn: async ({ pageParam }) => {
      const appointmentsRef = collection(fireStoreDb, "appointments");
      let q: Query = appointmentsRef;

      // Filter by user or provider
      if (currentUser.role === "provider") {
        q = query(
          q,
          where("providerId", "==", userInformationQuery.data?.providerId)
        );
      } else {
        q = query(q, where("userId", "==", currentUser?.uid));
      }

      // Filter by status
      if (status) {
        q = query(q, where("status", "==", status));
      }

      // Filter by appointment type
      if (appointmentTypes && appointmentTypes.length === 1) {
        if (appointmentTypes.includes("Exames")) {
          q = query(q, where("clinicId", "!=", null));
        } else if (appointmentTypes.includes("Consultas")) {
          q = query(q, where("clinicId", "==", null));
        }
      }

      // Order and filter by date range
      if (status === "WAITING_PROVIDER" || status === "CANCELLED") {
        q = query(q, orderBy("option1"));
        if (dateRange) {
          const start = DateUtils.toDBFormat(dateRange.start.toString());
          const end = DateUtils.queryToDBFormat(dateRange.end.toString());
          q = query(
            q,
            where("option1", ">=", start),
            where("option1", "<=", end)
          );
        }
      } else {
        // For confirmed and completed appointments, order by selectedOption if available, otherwise by option1
        q = query(q, orderBy("selectedOption"));

        if (dateRange) {
          const start = DateUtils.toDBFormat(dateRange.start.toString());
          const end = DateUtils.queryToDBFormat(dateRange.end.toString());
          q = query(
            q,
            where("selectedOption", ">=", start),
            where("selectedOption", "<=", end)
          );
        }
      }

      // Pagination
      if (pageParam) {
        const lastDocRef = doc(
          fireStoreDb,
          "appointments",
          pageParam as string
        );
        const lastDocSnap = await getDoc(lastDocRef);
        if (lastDocSnap.exists()) {
          q = query(q, startAfter(lastDocSnap));
        }
      }

      q = query(q, limit(APPOINTMENTS_PER_PAGE));
      const querySnapshot = await getDocs(q);
      const appointments = querySnapshot.docs.map(
        (doc) =>
          ({
            ...doc.data(),
            id: doc.id,
          } as Appointment)
      );

      return appointments;
    },
    getNextPageParam: (lastPage) => {
      if (lastPage.length < APPOINTMENTS_PER_PAGE) {
        return undefined; // No more pages
      }
      const lastAppointment = lastPage[lastPage.length - 1];
      return lastAppointment.id;
    },
    initialPageParam: null as string | null,
    staleTime: 5 * 60 * 1000,
    retry: 3,
    enabled:
      currentUser.role === "provider" ? !userInformationQuery.isFetching : true,
  });
};
