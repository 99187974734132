import React from "react";
import { BadgeCheck, BadgeInfo, BadgeMinus } from "lucide-react";

interface VerificationIconProps {
  isEmailVerified: boolean;
  isPhoneVerified: boolean;
}

export const VerificationIcon: React.FC<VerificationIconProps> = ({
  isEmailVerified,
  isPhoneVerified,
}) => {
  if (isEmailVerified && isPhoneVerified) {
    return <BadgeCheck className="w-4 text-success-500" />;
  } else if (isEmailVerified || isPhoneVerified) {
    return <BadgeInfo className="w-4 text-warning-500" />;
  } else {
    return <BadgeMinus className="w-4 text-danger-500" />;
  }
};
