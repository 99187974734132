import { useState, useEffect } from "react";
import { notificationService } from "../services/notifications/NotificationService";

export const useInstallPrompt = () => {
  const [showPrompt, setShowPrompt] = useState<boolean>(false);
  const [deferredPrompt, setDeferredPrompt] = useState<any>(null);
  const [buttonColor, setButtonColor] = useState<"primary" | "success">(
    "primary"
  );
  const [isIOS, setIsIOS] = useState(false);

  useEffect(() => {
    if (notificationService.isPWA()) return;
    const checkIOSDevice = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      setIsIOS(/iphone|ipad|ipod/.test(userAgent));
    };

    checkIOSDevice();

    if (!isIOS) {
      const handleBeforeInstallPrompt = (e: Event) => {
        e.preventDefault();
        setDeferredPrompt(e);
        setShowPrompt(true);
      };

      window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

      return () => {
        window.removeEventListener(
          "beforeinstallprompt",
          handleBeforeInstallPrompt
        );
      };
    } else {
      // For iOS, we'll show the prompt after a short delay
      const timer = setTimeout(() => setShowPrompt(true), 2000);
      return () => clearTimeout(timer);
    }
  }, [isIOS]);

  const handleInstall = async () => {
    if (!isIOS && deferredPrompt) {
      deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      if (outcome === "accepted") {
        setButtonColor("success");
        setTimeout(() => setShowPrompt(false), 1000);
      }
      setDeferredPrompt(null);
    }
  };

  const handleDismiss = () => setShowPrompt(false);

  return { showPrompt, handleInstall, handleDismiss, isIOS, buttonColor };
};
