export const appointmentObjectives: { key: string; value: string }[] = [
  {
    key: "Primeira Consulta",
    value: "Primeira Consulta",
  },
  {
    key: "Retorno",
    value: "Retorno",
  },
  {
    key: "Rotina",
    value: "Rotina",
  },
  {
    key: "Resultado de Exames",
    value: "Resultado de Exames",
  },
  {
    key: "Renovação de Receitas",
    value: "Renovação de Receitas",
  },
];
