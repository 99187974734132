export enum AppointmentStatus {
  WAITING_PROVIDER = "WAITING_PROVIDER",
  CONFIRMED = "CONFIRMED",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED",
}

export interface Appointment {
  id?: string;
  status: AppointmentStatus;

  providerId: string | null;
  clinicId: string | null;
  userId: string | null;
  examId?: string;
  serviceId?: string;
  option1: string | null; // ISO 8601 format
  option2?: string | null; // ISO 8601 format
  option3?: string | null; // ISO 8601 format
  selectedOption?: string | null; // ISO 8601 format
  notificationTimes?: string[]; // ISO 8601 format
  paymentMethod?: string | null;
  healthInsurance?: string | null;
  reason?: string;
  hasReview?: boolean;
}
