import React from "react";
import { MapPin, Clock, Bell } from "lucide-react";

interface IconProps {
  name: "mapPin" | "clock" | "bell";
  className?: string;
}

export const PreferenceIcon: React.FC<IconProps> = ({
  name,
  className = "w-4",
}) => {
  switch (name) {
    case "mapPin":
      return <MapPin className={className} />;
    case "clock":
      return <Clock className={className} />;
    case "bell":
      return <Bell className={className} />;
    default:
      return null;
  }
};
