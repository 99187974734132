import {
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { MedicalService } from "../../models/MedicalService";
import { fireStoreDb } from "../../services/firebase/firebase";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

// Function to fetch all medical services or medical services by type
const fetchMedicalServices = async (
  type?: MedicalService["type"]
): Promise<MedicalService[]> => {
  try {
    let q;
    if (type) {
      q = query(
        collection(fireStoreDb, "medicalServices"),
        where("type", "==", type)
      );
    } else {
      q = collection(fireStoreDb, "medicalServices");
    }

    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      type: doc.data().type,
      name: doc.data().name,
    }));
  } catch (error) {
    console.error("Error fetching medical services:", error);
    throw error;
  }
};

// Hook to fetch all medical services
export const useMedicalServicesQuery = (type?: MedicalService["type"]) => {
  return useQuery<MedicalService[], Error>({
    queryKey: type ? ["medicalServices", type] : ["medicalServices"],
    queryFn: () => fetchMedicalServices(type),
    staleTime: 5 * 60 * 1000, // 5 minutes cache
    retry: 2,
  });
};

// Hook to fetch specialties
export const useSpecialtiesQuery = () => useMedicalServicesQuery("specialty");

// Hook to fetch services
export const useExamsQuery = () => useMedicalServicesQuery("exam");

// Hook to fetch exams
export const useServicesQuery = () => useMedicalServicesQuery("service");

// Hook to add services
export const useAddMedicalService = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      name,
      type,
    }: {
      name: string;
      type: MedicalService["type"];
    }) => {
      // Input validation
      if (!name || name.trim().length < 2) {
        throw new Error("Invalid service name");
      }

      const sanitizedName = name.trim();

      // Use the sanitized name as the document ID
      const docRef = doc(
        collection(fireStoreDb, "medicalServices"),
        sanitizedName
      );

      await setDoc(docRef, {
        id: sanitizedName, // Explicitly set id to match the name
        type,
      });

      return { id: sanitizedName, name: sanitizedName, type };
    },
    onSuccess: (data) => {
      // Invalidate relevant queries
      queryClient.invalidateQueries({
        queryKey: ["medicalServices"],
      });
      queryClient.invalidateQueries({
        queryKey: ["medicalServices", data.type],
      });
    },
    onError: (error) => {
      console.error(`Mutation error:`, error);
    },
  });
};

// Hook to add exams
