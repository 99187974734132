import { Skeleton } from "@nextui-org/react";
import { Return } from "../../Molecules/Return";

export const BookingSkeleton = () => (
  <main className="flex flex-col justify-start gap-8 items-center min-h-svh p-4 w-full">
    <Return route="/" title="Agendar Consulta" />

    <div className="flex flex-col w-full gap-2 justify-center items-center">
      <div className="flex flex-col w-full gap-4 p-2 bg-white rounded-large">
        <div className="flex flex-col gap-2">
          <Skeleton className="flex justify-between items-start w-full h-14 rounded-small"></Skeleton>
          <Skeleton className="w-full h-3 rounded-small"></Skeleton>
          <Skeleton className="w-1/2 h-3 rounded-small"></Skeleton>
        </div>

        <div className="flex gap-1">
          <Skeleton className="w-2/5 h-5 rounded-medium"></Skeleton>
          <Skeleton className="w-1/4 h-5 rounded-medium"></Skeleton>
          <Skeleton className="w-3/5 h-5 rounded-medium"></Skeleton>
        </div>

        <div className="grid max-md:grid-cols-2 grid-cols-3 gap-1">
          <Skeleton className="flex p-2 rounded-small bg-warning-50 h-10"></Skeleton>
          <Skeleton className="flex p-2 rounded-small bg-primary-50 h-10"></Skeleton>
          <Skeleton className="flex col-span-2 p-2 rounded-small bg-secondary-50 h-10"></Skeleton>
        </div>
      </div>

      <div className="flex flex-col w-full gap-2 p-2 bg-white rounded-large">
        <Skeleton className="flex justify-between items-start w-full h-8  rounded-small"></Skeleton>
        <Skeleton className="flex justify-between items-start w-8/12 h-6  rounded-small"></Skeleton>
      </div>
      <div className="flex flex-col w-full gap-2 p-2 bg-white rounded-large">
        <Skeleton className="flex justify-between items-start w-full h-8  rounded-small"></Skeleton>
        <Skeleton className="w-full h-16 rounded-medium"></Skeleton>
      </div>
      <div className="flex flex-col w-full gap-2 p-2 bg-white rounded-large">
        <Skeleton className="flex justify-between items-start w-full h-8  rounded-small"></Skeleton>
        <div className="flex items-center gap-2 w-full">
          <Skeleton className="h-8 w-8 rounded-small bg-default-400"></Skeleton>
          <div className="flex gap-1 flex-grow overflow-x-auto">
            <Skeleton className=" min-w-[60px] w-full h-20 border-2 border-warning-200 bg-warning-200 rounded-medium"></Skeleton>
            <Skeleton className=" min-w-[60px] w-full h-auto border-2 border-warning-200 bg-white rounded-medium"></Skeleton>
            <Skeleton className=" min-w-[60px] w-full h-auto border-2 border-warning-200 bg-white rounded-medium"></Skeleton>
          </div>
          <Skeleton className="h-8 w-8 rounded-small bg-default-500"></Skeleton>
        </div>
        <Skeleton className="flex justify-between items-start w-full h-8  rounded-small"></Skeleton>
        <div className="grid gap-2 grid-cols-4">
          <Skeleton className="w-full h-10 rounded-medium border-2 border-warning-200 bg-white"></Skeleton>
          <Skeleton className="w-full h-10 rounded-medium border-2 border-warning-200 bg-white"></Skeleton>
          <Skeleton className="w-full h-10 rounded-medium border-2 border-warning-200 bg-white"></Skeleton>
          <Skeleton className="w-full h-10 rounded-medium border-2 border-warning-200 bg-white"></Skeleton>
          <Skeleton className="w-full h-10 rounded-medium border-2 border-warning-200 bg-white"></Skeleton>
          <Skeleton className="w-full h-10 rounded-medium border-2 border-warning-200 bg-white"></Skeleton>
          <Skeleton className="w-full h-10 rounded-medium border-2 border-warning-200 bg-white"></Skeleton>
          <Skeleton className="w-full h-10 rounded-medium border-2 border-warning-200 bg-white"></Skeleton>
        </div>
        <Skeleton className="flex gap-1.5 p-2 rounded-medium h-10 border-2 bg-white"></Skeleton>
        <Skeleton className="flex gap-1.5 p-2 rounded-medium bg-primary-100 h-10"></Skeleton>
      </div>
    </div>
  </main>
);
