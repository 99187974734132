import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@nextui-org/react";
import { LocateFixed } from "lucide-react";

type GeolocationState =
  | "idle"
  | "requesting"
  | "success"
  | "denied"
  | "error"
  | "unsupported";

interface GeolocationHandlerProps {
  onLocationSuccess: (position: GeolocationPosition) => void;
  onLocationError: (error: GeolocationPositionError) => void;
}

export const GeolocationHandler: React.FC<GeolocationHandlerProps> = ({
  onLocationSuccess,
  onLocationError,
}) => {
  const [geolocationState, setGeolocationState] =
    useState<GeolocationState>("idle");
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    if (!navigator.geolocation) {
      setGeolocationState("unsupported");
    }
  }, []);

  const requestGeolocation = () => {
    setGeolocationState("requesting");
    navigator.geolocation.getCurrentPosition(
      (position: GeolocationPosition) => {
        setGeolocationState("success");
        onLocationSuccess(position);
      },
      (error: GeolocationPositionError) => {
        if (error.code === error.PERMISSION_DENIED) {
          setGeolocationState("denied");
          setShowModal(true);
        } else {
          setGeolocationState("error");
          onLocationError(error);
        }
      }
    );
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <Button
        size="sm"
        variant="light"
        color="primary"
        startContent={<LocateFixed className="w-4" />}
        className="font-medium px-0"
        onPress={requestGeolocation}
        isDisabled={
          geolocationState === "requesting" ||
          geolocationState === "unsupported"
        }
      >
        {geolocationState === "requesting"
          ? "Localizando..."
          : "Usar localização atual"}
      </Button>

      <Modal isOpen={showModal} onClose={handleCloseModal}>
        <ModalContent>
          <ModalHeader>Permissão de Localização Negada</ModalHeader>
          <ModalBody>
            Para usar sua localização atual, você precisa permitir o acesso à
            sua localização nas configurações do seu navegador.
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onPress={handleCloseModal}>
              Entendi
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
