import { Avatar, Button, Card, Chip } from "@nextui-org/react";
import { MapPin, Hospital, Phone, Star } from "lucide-react";
import { ProviderInformation } from "../../../models/ProviderInformation";

export const ProviderCard = ({
  provider,
  onCardClick,
}: {
  provider: ProviderInformation;
  onCardClick: () => void;
}) => {
  // Helper function to truncate text
  const truncateText = (text: string, maxLength: number = 30) => {
    if (!text) return "";
    return text.length > maxLength
      ? `${text.substring(0, maxLength)}...`
      : text;
  };

  return (
    <Card
      className="flex flex-col gap-3 bg-white rounded-large p-2 w-full max-w-md min-w-80 cursor-pointer"
      shadow="none"
      isPressable
      tabIndex={-1}
      onPress={onCardClick}
    >
      <div className="flex justify-between items-start gap-2 w-full">
        <div className="flex gap-3 overflow-hidden">
          <Avatar
            src={provider.imageUrl || ""}
            radius="md"
            className="h-16 w-16 flex-shrink-0"
          />
          <div className="flex flex-col mt-1 items-start overflow-hidden">
            <span className="text-sm font-medium text-default-900 truncate">
              {provider.name}
            </span>
            <span className="text-sm font-medium text-default-400 truncate">
              {provider.crmCode}
            </span>
          </div>
        </div>
        <Chip
          variant="flat"
          color="warning"
          startContent={<Star className="w-4 ml-1 stroke-[3]" />}
          className="flex-shrink-0"
        >
          {provider.averageRating || "?"}
        </Chip>
      </div>

      <div className="flex flex-col gap-2 w-full">
        <div className="flex items-center gap-2 overflow-hidden">
          <MapPin className="w-4 flex-shrink-0" />
          <span className="truncate">
            {truncateText(provider.relatedClinic?.address || "", 40)}
          </span>
        </div>
        <div className="flex items-center gap-2 overflow-hidden">
          <Hospital className="w-4 flex-shrink-0" />
          <span className="truncate">
            {truncateText(provider.relatedClinic?.name || "", 40)}
          </span>
        </div>
        <div className="flex items-center gap-2 overflow-hidden">
          <Phone className="w-4 flex-shrink-0" />
          <span className="truncate">
            {truncateText(provider.relatedClinic?.phoneNumber || "", 30)}
          </span>
        </div>
      </div>

      <Button
        radius="md"
        variant="flat"
        color="primary"
        className="font-semibold w-full mt-2"
        onPress={onCardClick}
      >
        Ver detalhes
      </Button>
    </Card>
  );
};
