import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { collection, doc, getDocs, setDoc } from "firebase/firestore";
import { fireStoreDb } from "../../services/firebase/firebase";
import { HealthInsurance } from "../../models/HealthInsurance";

// Fetch Health Insurances
const fetchHealthInsurances = async (): Promise<HealthInsurance[]> => {
  try {
    const querySnapshot = await getDocs(
      collection(fireStoreDb, "healthInsurances")
    );
    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
    }));
  } catch (error) {
    console.error("Error fetching health insurances:", error);
    throw error;
  }
};

// Health Insurances Query Hook
export const useHealthInsurancesQuery = () => {
  return useQuery<HealthInsurance[], Error>({
    queryKey: ["healthInsurances"],
    queryFn: fetchHealthInsurances,
    staleTime: 5 * 60 * 1000, // 5 minutes cache
    retry: 2,
  });
};

// Add Health Insurance Mutation Hook
export const useAddHealthInsurance = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (name: string) => {
      // Input validation
      if (!name || name.trim().length < 2) {
        throw new Error("Invalid health insurance name");
      }

      const sanitizedName = name.trim();

      // Use the sanitized name as the document ID
      const docRef = doc(
        collection(fireStoreDb, "healthInsurances"),
        sanitizedName
      );

      await setDoc(docRef, {
        id: sanitizedName,
      });

      return { id: sanitizedName, name: sanitizedName };
    },
    onSuccess: () => {
      // Invalidate health insurances query
      queryClient.invalidateQueries({
        queryKey: ["healthInsurances"],
      });
    },
    onError: (error) => {
      console.error(`Mutation error:`, error);
    },
  });
};
