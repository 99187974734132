import { doc, getDoc, setDoc } from "firebase/firestore";
import { fireStoreDb, functions } from "../../services/firebase/firebase";
import { Appointment } from "../../models/Appointment";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { httpsCallable } from "firebase/functions";
import { DateUtils } from "../../utils/dateUtils";

interface ConfirmAppointmentParams {
  id: string;
  date: string;
}

const confirmAppointment = async ({ id, date }: ConfirmAppointmentParams) => {
  const formattedDate = DateUtils.toDBFormat(date);
  if (!formattedDate) {
    throw new Error("Invalid date format");
  }

  const appointmentDoc = await getDoc(doc(fireStoreDb, "appointments", id));
  const userId = appointmentDoc.data()?.userId;

  // Calculate notification times: 48 hours and 24 hours before the appointment
  const appointmentDate = new Date(date);
  const notificationTimes = [
    (appointmentDate.getTime() - 48 * 60 * 60 * 1000).toString(),
    (appointmentDate.getTime() - 24 * 60 * 60 * 1000).toString(),
  ];

  await setDoc(
    doc(fireStoreDb, "appointments", id),
    {
      status: "CONFIRMED",
      selectedOption: date,
      notificationTimes,
    } as Appointment,
    { merge: true }
  );

  if (userId) {
    const sendNotification = httpsCallable(
      functions,
      "sendAppointmentNotification",
      { limitedUseAppCheckTokens: true }
    );
    const scheduleNotifications = httpsCallable(
      functions,
      "scheduleNotifications",
      { limitedUseAppCheckTokens: true }
    );
    const { formattedDate: displayDate, formattedTime: displayTime } =
      DateUtils.getFormattedDateAndTime(date);

    try {
      await sendNotification({
        appointmentId: id,
        userId,
        title: "Agendamento Confirmado",
        body: `Seu agendamento Zip Saúde no dia ${displayDate} às ${displayTime} foi confirmado. Toque para ver mais informações.`,
      });
      console.log("Confirmation notification sent successfully");

      scheduleNotifications({ appointmentId: id });
    } catch (error) {
      console.error(
        "Error sending notification or scheduling notifications:",
        error
      );
    }
  }
};

export const useConfirmAppointment = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: ConfirmAppointmentParams) =>
      confirmAppointment(params),

    onMutate: async (newAppointment) => {
      await queryClient.cancelQueries({ queryKey: ["appointments"] });

      const previousAppointments = queryClient.getQueryData(["appointments"]);

      queryClient.setQueryData(["appointments"], (old: any) => {
        if (!old || !old.pages) {
          console.warn("Appointments data structure is not as expected");
          return old;
        }

        return {
          ...old,
          pages: old.pages.map((page: Appointment[]) =>
            Array.isArray(page)
              ? page.map((appointment) =>
                  appointment.id === newAppointment.id
                    ? {
                        ...appointment,
                        status: "CONFIRMED",
                        selectedOption: newAppointment.date,
                      }
                    : appointment
                )
              : page
          ),
        };
      });

      return { previousAppointments };
    },

    onError: (_, __, context) => {
      if (context?.previousAppointments) {
        queryClient.setQueryData(
          ["appointments"],
          context.previousAppointments
        );
      }
    },

    onSettled: async (_, __, params) => {
      await queryClient.invalidateQueries({ queryKey: ["appointments"] });
      await queryClient.invalidateQueries({
        queryKey: ["appointment", params.id],
      });
    },
  });
};
