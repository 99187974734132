import { AppointmentStatus } from "../models/Appointment";

type StatusColor = "warning" | "success" | "danger" | "secondary" | "default";

export const getColorByStatusCode = (
  status: AppointmentStatus
): { bg: string; text: string; color: StatusColor; description: string } => {
  switch (status) {
    case AppointmentStatus.WAITING_PROVIDER:
      return {
        bg: "bg-warning-100/50",
        text: "text-warning-900",
        color: "warning",
        description: "Pendente",
      };
    case AppointmentStatus.CONFIRMED:
      return {
        bg: "bg-success-100/50",
        text: "text-success-900",
        color: "success",
        description: "Confirmado",
      };
    case AppointmentStatus.CANCELLED:
      return {
        bg: "bg-danger-100/50",
        text: "text-danger-900",
        color: "danger",
        description: "Cancelado",
      };
    case AppointmentStatus.COMPLETED:
      return {
        bg: "bg-secondary-100/50",
        text: "text-secondary-900",
        color: "secondary",
        description: "Concluído",
      };
    default:
      return {
        bg: "bg-default-100/50",
        text: "text-default-900",
        color: "default",
        description: "Histórico",
      };
  }
};
