import React from "react";
import { Button, Chip } from "@nextui-org/react";
import { Star } from "lucide-react";
import { ContactInfoGrid } from "../../Molecules/ContactInfoGrid";
import { PaymentMethod } from "../../../models/PaymentMethod";
import { EntityInfo } from "../../Molecules/EntityInfo";
import { Clinic } from "../../../models/Clinic";
import { ProviderInformation } from "../../../models/ProviderInformation";

interface ContactInfo {
  phoneNumber: string;
  name: string;
  address: string;
  timezone: string;
}

interface BookingHeaderProps {
  clinic?: Clinic;
  provider?: ProviderInformation;
  acceptedPaymentMethods?: PaymentMethod[];
  contactInfo: ContactInfo;
  userTimezone: string;
  onOpen: () => void;
}

export const BookingHeader: React.FC<BookingHeaderProps> = ({
  clinic,
  provider,
  acceptedPaymentMethods,
  contactInfo,
  userTimezone,
  onOpen,
}) => (
  <div className="flex flex-col gap-5 p-2 w-full bg-white rounded-large">
    <div className="flex justify-between items-start w-full">
      <EntityInfo
        clinic={clinic}
        provider={provider}
        titleClassName="text-lg font-bold text-default-800"
        descriptionClassName="text-sm font-semibold text-default-600"
        avatarClassName="w-16 h-16"
      />
      {provider !== undefined && (
        <Chip
          variant="flat"
          color="primary"
          startContent={<Star className="w-4" />}
          classNames={{ content: "font-semibold px-1" }}
        >
          {provider.averageRating}
        </Chip>
      )}
    </div>

    {provider && (
      <p className="text-sm font-medium text-default-700 px-0.5">
        {provider.readme}
      </p>
    )}

    <div className="flex flex-col gap-3">
      <ContactInfoGrid
        phoneNumber={contactInfo.phoneNumber}
        name={contactInfo.name}
        address={contactInfo.address}
        timezone={contactInfo.timezone}
        userTimezone={userTimezone}
        methods={acceptedPaymentMethods as PaymentMethod[]}
      />
    </div>

    <Button color="primary" size="md" radius="md" onPress={onOpen}>
      Agendar Consulta
    </Button>
  </div>
);
