import { Avatar } from "../../Atoms/Avatar";
import { Spinner } from "@nextui-org/react";

const getInitials = (name: string) => {
  const names = name.split(" ");
  if (names.length === 1) return names[0].slice(0, 2).toUpperCase();
  return (names[0][0] + names[names.length - 1][0]).toUpperCase();
};

export const UserInfo = ({
  userInfo,
  description,
  isLoading,
}: {
  userInfo: any;
  description: string;
  isLoading: boolean;
}) => {
  const initials = userInfo?.name
    ? getInitials(userInfo.name)
    : userInfo?.email?.slice(0, 2).toUpperCase();

  return (
    <div className="flex gap-2">
      <Avatar
        isBordered={false}
        name={initials}
        src={userInfo?.photoURL || ""}
      />
      <div className="flex flex-col">
        <span className="text-sm font-medium text-default-900">
          {isLoading ? (
            <Spinner size="sm" />
          ) : (
            userInfo?.name || userInfo?.email
          )}
        </span>
        <span className="text-sm font-medium text-default-400">
          {description}
        </span>
      </div>
    </div>
  );
};
