import { Capacitor } from "@capacitor/core";
import { FirebaseMessaging as PushNotifications } from "@capacitor-firebase/messaging";
import { doc, updateDoc } from "firebase/firestore";
import { auth, fireStoreDb, messaging } from "../firebase/firebase";
import { getToken, onMessage } from "firebase/messaging";
import { NavigateFunction } from "react-router-dom";
import { toast } from "react-toastify";

interface NotificationData {
  URL?: string;
  title?: string;
  body?: string;
}

const VAPID_KEY =
  "BMcPCXQ2gMgn2YgSt2NmPepL2i5Lk1pIiJkTjfHynRnHlWPuRhGYmO5azgs9A32nXP2tZ10Utqm7zQuz5HWkzNE";

class NotificationService {
  private initialized = false;
  private navigate: NavigateFunction | null = null;

  async initialize(navigate: NavigateFunction) {
    if (this.initialized) return;
    this.navigate = navigate;

    if (Capacitor.isNativePlatform()) {
      await this.setupMobileNotifications();
    } else {
      // Existing web/PWA logic
      if (this.isPWA()) {
        console.log(
          "Running as PWA, waiting for user interaction to request notification permission"
        );
      } else {
        await this.setupWebNotifications();
      }
    }

    this.setupForegroundMessaging();
    this.initialized = true;
  }

  public isPWA() {
    return (
      window.matchMedia("(display-mode: standalone)").matches ||
      (window.navigator as any).standalone
    );
  }

  private async setupMobileNotifications() {
    try {
      // Request permissions
      const permissionResult = await PushNotifications.requestPermissions();

      if (permissionResult.receive === "granted") {
        // Add listeners for push notifications
        PushNotifications.addListener("tokenReceived", async (token) => {
          console.log("Push token received", token);
          await this.storeMobileToken(token.token);
        });

        PushNotifications.addListener(
          "notificationReceived",
          (notification) => {
            console.log("Notification Received!", notification);
            this.handleMobileNotification(notification);
          }
        );

        await PushNotifications.addListener(
          "notificationActionPerformed",
          (action) => {
            const notificationData = action.notification
              .data as NotificationData;

            if (notificationData?.URL) {
              this.handleNotificationClick(notificationData.URL);
            }
          }
        );
      }
    } catch (error) {
      console.error("Error setting up mobile notifications", error);
    }
  }

  private async setupWebNotifications() {
    await this.requestNotificationPermission();
  }

  async requestNotificationPermission() {
    if (Capacitor.isNativePlatform()) return;

    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      await this.getAndStoreWebToken();
    }
    return permission;
  }

  private async getAndStoreWebToken() {
    try {
      const token = await getToken(messaging, { vapidKey: VAPID_KEY });
      if (token) {
        await this.storeUserToken(token);
      }
    } catch (error) {
      console.error("An error occurred while retrieving web token:", error);
    }
  }

  private async storeUserToken(token: string) {
    const user = auth.currentUser;
    if (user) {
      const userRef = doc(fireStoreDb, "users", user.uid);
      await updateDoc(userRef, { fcmToken: token });
    }
  }

  private async storeMobileToken(token: string) {
    const user = auth.currentUser;
    if (user) {
      const userRef = doc(fireStoreDb, "users", user.uid);
      await updateDoc(userRef, { fcmToken: token });
    }
  }

  private setupForegroundMessaging() {
    if (Capacitor.isNativePlatform()) return;

    onMessage(messaging, (payload: any) => {
      const { body, imageUrl, URL } = payload.data;

      toast.info(
        <div
          onClick={() => this.handleNotificationClick(URL)}
          className="flex items-start gap-2 cursor-pointer"
        >
          <img
            src={imageUrl || "/images/icons/icon.png"}
            alt="Notification Icon"
            className="w-12 h-12 rounded-medium"
          />
          <p>{body}</p>
        </div>,
        {
          autoClose: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          icon: false,
        }
      );
    });
  }

  private handleMobileNotification(notification: any) {
    toast.info(
      <div
        onClick={() => this.handleNotificationClick(notification.data.URL)}
        className="flex items-start gap-2 cursor-pointer"
      >
        <p>{notification.body}</p>
      </div>,
      {
        autoClose: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        icon: false,
      }
    );
  }

  private handleNotificationClick(url: string) {
    if (this.navigate) {
      this.navigate(url);
    } else {
      console.error("Navigation function not set");
    }
  }
}

export const notificationService = new NotificationService();
