import { useState, useMemo, useCallback } from "react";
import { State } from "../models/State";
import { City } from "../models/City";
import { useIBGEStatesQuery } from "./queries/useIBGEStates";
import { useSearchProvidersQuery } from "./queries/useProviderQuery";
import { useUserLocation } from "./queries/useUserLocation";
import { useIBGECitiesQuery } from "./queries/useIBGECities";
import { useMedicalServicesQuery } from "./queries/useMedicalServicesQuery";
import { useAuth } from "../contexts/auth.context";
import { MedicalService } from "../models/MedicalService";
import { useSearchClinicsQuery } from "./queries/useClinicQuery";
import { queryClient } from "../libs/queryClient";
import {
  getCurrentLocation,
  harmonizeLocation,
  reverseGeocode,
} from "../utils/locationUtils";
import { PaymentMethod } from "../models/PaymentMethod";
import { useHealthInsurancesQuery } from "./queries/useHealthInsurancesQuery";

export const useProviderSearch = () => {
  const { currentUser } = useAuth();

  const [selectedState, setSelectedState] = useState<State | undefined>(
    currentUser?.location?.state as State
  );
  const [selectedCity, setSelectedCity] = useState<City | undefined>(
    currentUser?.location?.city as City
  );
  const [selectedMedicalService, setSelectedMedicalService] = useState<
    MedicalService | undefined
  >();
  const [filterText, setFilterText] = useState<string>("");
  const [selectedPaymentMethods, setSelectedPaymentMethods] = useState<
    PaymentMethod[]
  >([]);
  const [selectedHealthInsurance, setSelectedHealthInsurance] =
    useState<string>("");
  const [priceRange, setPriceRange] = useState<[number, number]>([0, 1000]);
  const [locationLoading, setLocationLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const pageSize = 10;

  const {
    data: states,
    isLoading: isLoadingStates,
    refetch: refetchStates,
  } = useIBGEStatesQuery();
  const { data: cities, isLoading: isLoadingCities } =
    useIBGECitiesQuery(selectedState);
  const { data: healthInsurances, isLoading: isLoadingHealthInsurances } =
    useHealthInsurancesQuery();

  const { data: providersData, isLoading: isLoadingProviders } =
    useSearchProvidersQuery({
      stateId: selectedState?.id as string,
      cityId: selectedCity?.id as string,
      specialtyId:
        selectedMedicalService?.type === "specialty"
          ? selectedMedicalService.id
          : undefined,
      serviceId:
        selectedMedicalService?.type === "service"
          ? selectedMedicalService.id
          : undefined,
      filterText,
      paymentMethods: selectedPaymentMethods,
      selectedHealthInsurance,
      priceRange,
      page,
      pageSize,
    });

  const { data: clinics, isLoading: isLoadingClinics } = useSearchClinicsQuery({
    stateId: selectedState?.id,
    cityId: selectedCity?.id,
    examId:
      selectedMedicalService?.type === "exam"
        ? selectedMedicalService.id
        : undefined,
    filterText,
    paymentMethods: selectedPaymentMethods,
    selectedHealthInsurance,
    priceRange,
    page,
    pageSize,
  });

  const { data: medicalServices, isLoading: isLoadingMedicalServices } =
    useMedicalServicesQuery();

  const { manualLocationMutation, isManualLocationMutationLoading } =
    useUserLocation();

  const stateItems = useMemo(() => {
    return (states?.map((state) => ({
      key: state.id,
      value: state.id,
      label: state.nome,
    })) || []) as { key: string; value: string; label: string }[];
  }, [states]);

  const cityItems = useMemo(() => {
    return (cities?.map((city: City) => ({
      key: city.id,
      value: city.id,
      label: city.nome,
    })) || []) as { key: string; value: string; label: string }[];
  }, [cities]);

  const medicalServiceItems =
    medicalServices?.map((medicalService) => ({
      key: medicalService.id,
      value: medicalService.id,
      label: medicalService.id,
      type: medicalService.type,
    })) || [];

  const handleStateChange = (stateId: string) => {
    const state = states?.find((s) => s.id == stateId);
    setSelectedState(state);
    setSelectedCity(undefined);
    setSelectedMedicalService(undefined);
    setFilterText("");
  };

  const handleCityChange = (cityId: string) => {
    const city = cities?.find((c: City) => c.id == cityId);
    setSelectedCity(city);
    setSelectedMedicalService(undefined);
    setFilterText("");
  };

  const handleMedicalServiceChange = (medicalServiceId: string) => {
    const medicalService = medicalServices?.find(
      (m: MedicalService) => m.id == medicalServiceId
    );
    setSelectedMedicalService(medicalService);
    setFilterText("");
  };

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
  };

  const handleFilterChange = (
    text: string,
    paymentMethods: PaymentMethod[],
    healthInsurance: string,
    newPriceRange: [number, number]
  ) => {
    setFilterText(text);
    setSelectedPaymentMethods(paymentMethods);
    setSelectedHealthInsurance(healthInsurance);
    setPriceRange(newPriceRange);
  };

  const handleLocationSearch = async () => {
    setLocationLoading(true);
    try {
      const { latitude, longitude } = await getCurrentLocation();

      const geocodedLocation = await reverseGeocode(latitude, longitude);
      const allStates = states || (await refetchStates()).data;
      if (!allStates) throw new Error("Failed to fetch states");

      const harmonizedLocation = await harmonizeLocation(
        geocodedLocation,
        allStates,
        async (state) => {
          const cities = await queryClient.fetchQuery({
            queryKey: ["IBGECities", state.id],
            queryFn: async () => {
              const response = await fetch(
                `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state.id}/municipios`
              );
              if (!response.ok) {
                throw new Error("Network response was not ok");
              }
              return response.json() as Promise<City[]>;
            },
          });
          return cities || [];
        }
      );

      if (harmonizedLocation.state && harmonizedLocation.city) {
        setSelectedState(harmonizedLocation.state);
        setSelectedCity(harmonizedLocation.city);
        await handleManualLocationUpdate(
          harmonizedLocation.state,
          harmonizedLocation.city
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLocationLoading(false);
    }
  };

  const handleManualLocationUpdate = async (state: State, city: City) => {
    try {
      await manualLocationMutation.mutateAsync({ state, city });
    } catch (error) {
      console.error("Error updating location:", error);
    }
  };

  const handlePageChange = useCallback((newPage: number) => {
    setPage(newPage);
  }, []);

  const isSpecialtySearch = useMemo(() => {
    return selectedMedicalService?.type === "specialty";
  }, [selectedMedicalService]);

  return {
    selectedState,
    selectedCity,
    selectedMedicalService,
    providers: providersData?.providers || [],
    totalProviders: providersData?.totalCount || 0,
    clinics: clinics?.clinics || [],
    totalClinics: clinics?.totalCount || 0,
    page,
    pageSize,
    filterText,
    selectedPaymentMethods,
    selectedHealthInsurance,
    healthInsurances,
    priceRange,
    stateItems,
    cityItems,
    medicalServiceItems,
    isLoadingStates,
    isLoadingCities,
    isLoadingMedicalServices,
    isLoadingProviders,
    isLoadingClinics,
    isLoadingHealthInsurances,
    isManualLocationMutationLoading,
    locationLoading,
    handleStateChange,
    handleCityChange,
    handleMedicalServiceChange,
    handleSearch,
    handleFilterChange,
    handleLocationSearch,
    handleManualLocationUpdate,
    handlePageChange,
    isSpecialtySearch,
  };
};
