import { Capacitor } from "@capacitor/core";
import { Geolocation } from "@capacitor/geolocation";
import axios from "axios";
import { City } from "../models/City";
import { State } from "../models/State";

interface Location {
  state: string;
  city: string;
}

const findClosestMatch = <T extends { nome: string | null }>(
  name: string,
  items: T[]
): T | undefined => {
  return items.find(
    (item) =>
      item.nome?.toLowerCase().includes(name.toLowerCase()) ||
      name.toLowerCase().includes(item.nome?.toLowerCase() || "")
  );
};

export const reverseGeocode = async (
  latitude: number,
  longitude: number
): Promise<Location> => {
  const response = await axios.get(
    `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`
  );
  const { address } = response.data;
  return {
    state: address.state,
    city: address.city || address.town || address.village,
  };
};

export const harmonizeLocation = async (
  geocodedLocation: Location,
  states: State[],
  getCities: (state: State) => Promise<City[]>
): Promise<{ state: State | undefined; city: City | undefined }> => {
  const closestState = findClosestMatch(geocodedLocation.state, states);

  if (!closestState) {
    throw new Error("Could not find a matching state");
  }

  const cities = await getCities(closestState);
  const closestCity = findClosestMatch(geocodedLocation.city, cities);

  return {
    state: closestState,
    city: closestCity,
  };
};

export const getCurrentLocation = async (): Promise<{
  latitude: number;
  longitude: number;
}> => {
  // Check if the app is running on a mobile platform
  if (!Capacitor.isNativePlatform()) {
    // Fallback to browser geolocation for web
    return new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        reject(new Error("Geolocation is not supported by your browser"));
      } else {
        navigator.geolocation.getCurrentPosition(
          (position) =>
            resolve({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            }),
          reject
        );
      }
    });
  }

  try {
    // Request location permissions
    await Geolocation.requestPermissions();

    // Get current position
    const position = await Geolocation.getCurrentPosition({
      enableHighAccuracy: true,
      timeout: 10000,
      maximumAge: 0,
    });

    return {
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    };
  } catch (error) {
    console.error("Error getting location", error);
    throw error;
  }
};
