import { useState, useEffect, useRef } from "react";
import { Banknote, ChevronDown, Check, Ban } from "lucide-react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  Button,
} from "@nextui-org/react";
import { motion, AnimatePresence } from "framer-motion";
import {
  PaymentMethod,
  PAYMENT_METHOD_LABELS,
  PaymentMethodIcon,
  PAYMENT_METHODS,
} from "../../../models/PaymentMethod";

export const PaymentMethodsList = ({
  methods,
}: {
  methods: PaymentMethod[];
}) => {
  // If no methods, return null
  if (!methods || methods.length === 0) return null;

  // Create a set of accepted methods for efficient lookup
  const acceptedMethodsSet = new Set(methods);

  // State for popover
  const [isOpen, setIsOpen] = useState(false);

  // State for viewport
  const [isMobile, setIsMobile] = useState(false);

  // Determine if truncation is needed
  const methodLabels = methods.map((method) => PAYMENT_METHOD_LABELS[method]);
  const [isTruncated, setIsTruncated] = useState(false);
  const containerRef = useRef<HTMLSpanElement>(null);

  // Check viewport and truncation
  useEffect(() => {
    const checkViewport = () => {
      setIsMobile(window.innerWidth <= 640); // Tailwind's sm breakpoint
    };

    const checkTruncation = () => {
      if (containerRef.current) {
        setIsTruncated(
          containerRef.current.scrollWidth > containerRef.current.offsetWidth
        );
      }
    };

    // Initial checks
    checkViewport();
    checkTruncation();

    // Add event listeners
    window.addEventListener("resize", checkViewport);
    window.addEventListener("resize", checkTruncation);

    // Cleanup
    return () => {
      window.removeEventListener("resize", checkViewport);
      window.removeEventListener("resize", checkTruncation);
    };
  }, [methodLabels]);

  // Render method for popover content
  const renderMethodsList = () => (
    <div className="flex flex-col gap-2 w-full">
      <AnimatePresence>
        {PAYMENT_METHODS.map((method) => (
          <motion.div
            key={method}
            className={`flex items-center justify-between p-2 rounded-small transition-colors`}
            initial={{ opacity: 0, x: -10 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -10 }}
            transition={{
              type: "spring",
              delay: PAYMENT_METHODS.indexOf(method) * 0.1,
            }}
          >
            <div
              className={`flex items-center gap-2 ${
                acceptedMethodsSet.has(method) ? "" : "opacity-50"
              }`}
            >
              <PaymentMethodIcon method={method} />
              <span className={`text-sm `}>
                {PAYMENT_METHOD_LABELS[method]}
              </span>
            </div>
            {acceptedMethodsSet.has(method) ? (
              <motion.div
                initial={{ scale: 0, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{
                  type: "spring",
                  delay: PAYMENT_METHODS.indexOf(method) * 0.15,
                }}
                className="text-success-500"
              >
                <Check className="w-5 h-5" />
              </motion.div>
            ) : (
              <motion.div
                initial={{ scale: 0, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{
                  type: "spring",
                  delay: PAYMENT_METHODS.indexOf(method) * 0.15,
                }}
                className="text-default-500"
              >
                <Ban className="w-5 h-5" />
              </motion.div>
            )}
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  );

  // Render method for trigger content
  const renderTriggerContent = () => {
    if (isMobile) {
      return (
        <Button
          color="success"
          variant="light"
          size="sm"
          className="w-full font-medium text-sm text-success-700 justify-between p-0"
          endContent={
            <ChevronDown
              className={`w-4 transition-transform ${
                isOpen ? "rotate-180" : ""
              }`}
            />
          }
        >
          Ver métodos de pagamento aceitos
        </Button>
      );
    }

    return (
      <div className="flex items-center gap-1 min-w-0 flex-grow cursor-pointer">
        <span
          ref={containerRef}
          className="text-sm font-medium text-success-800 truncate flex-grow"
        >
          {methodLabels.join(", ")}
        </span>
        <motion.span
          animate={{ rotate: isOpen ? 180 : 0 }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
          className="flex-shrink-0"
        >
          <ChevronDown className="text-success-600 w-4" />
        </motion.span>
      </div>
    );
  };

  return (
    <div className="flex items-center justify-start gap-1.5 p-2 rounded-medium w-full">
      <Banknote className="text-success-600 w-6 stroke-[2] flex-shrink-0" />

      <div className="flex items-center gap-1 min-w-0 flex-grow overflow-hidden">
        {(isTruncated || isMobile) && (
          <Popover
            placement="bottom"
            radius="md"
            isOpen={isOpen}
            onOpenChange={(open) => setIsOpen(open)}
          >
            <PopoverTrigger>{renderTriggerContent()}</PopoverTrigger>
            <PopoverContent className="p-3 flex flex-col gap-4 min-w-[200px]">
              <span className="text-small font-medium pr-2">
                Métodos de Pagamento Aceitos
              </span>
              {renderMethodsList()}
            </PopoverContent>
          </Popover>
        )}

        {!isTruncated && !isMobile && (
          <span
            ref={containerRef}
            className="text-sm font-medium text-success-800 truncate"
          >
            {methodLabels.join(", ")}
          </span>
        )}
      </div>
    </div>
  );
};
