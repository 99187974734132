import React, { useEffect, useState } from "react";
import { Bell } from "lucide-react";
import { Prompt } from "../../Atoms/Prompt";
import { Button } from "@nextui-org/react";
import { useNotificationPermission } from "../../../hooks/useNotificationPrompt";

export const NotificationPrompt: React.FC = () => {
  const { permissionState, requestPermission, isPWA } =
    useNotificationPermission();
  const [showPrompt, setShowPrompt] = useState(false);
  const [buttonColor, setButtonColor] = useState<"primary" | "success">(
    "primary"
  );

  useEffect(() => {
    setShowPrompt(permissionState === "default" && isPWA);
  }, [permissionState, isPWA]);

  const handleEnable = async () => {
    const permission = await requestPermission();
    if (permission === "granted") {
      setButtonColor("success");
      setTimeout(() => setShowPrompt(false), 1000);
    }
  };

  const handleDismiss = () => setShowPrompt(false);
  return (
    <Prompt
      show={showPrompt}
      onDismiss={handleDismiss}
      icon={<Bell className="w-12 h-12 text-primary" />}
      message={
        <span className="flex-grow text-sm sm:text-base">
          Ative as notificações para receber atualizações importantes do Zip
          Saúde
        </span>
      }
      confirmButton={
        <Button
          color={buttonColor}
          isDisabled={buttonColor === "success"}
          onPress={handleEnable}
          size="sm"
        >
          Ativar Notificações
        </Button>
      }
      cancelText="Ativar Depois"
    />
  );
};
