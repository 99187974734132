import React from "react";
import { useDisclosure } from "@nextui-org/react";
import { Appointment, AppointmentStatus } from "../../../models/Appointment";
import { CancelAppointmentModal } from "../CancelAppointmentModal";
import { ActionButton } from "../../Atoms/ActionButton";
import { DateUtils } from "../../../utils/dateUtils";

interface CancelAppointmentProps {
  appointment: Appointment;
  currentUser: { role: "customer" | "provider" };
}

export const CancelAppointment: React.FC<CancelAppointmentProps> = ({
  appointment,
  currentUser,
}) => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const isCancelable = DateUtils.isCancelable(
    appointment.selectedOption || appointment.option1
  );

  const currentDate = DateUtils.now();
  const appointmentDate = DateUtils.fromDBFormat(
    appointment.selectedOption || appointment.option1
  );

  if (
    ![AppointmentStatus.WAITING_PROVIDER, AppointmentStatus.CONFIRMED].includes(
      appointment.status
    )
  )
    return null;

  return (
    <>
      <ActionButton
        variant="flat"
        color="danger"
        size="lg"
        radius="md"
        className="bg-danger-50 text-danger"
        onPress={onOpen}
        isDisabled={currentDate.isAfter(appointmentDate)}
        label="Cancelar Agendamento"
      />

      <CancelAppointmentModal
        isOpen={isOpen}
        appointmentId={appointment.id as string}
        onOpenChange={onOpenChange}
        role={currentUser.role}
        description={
          isCancelable
            ? "Essa ação não poderá ser revertida."
            : "Essa ação gerará uma PENALIDADE. Não proibimos cancelamos com menos de 24hs, mas é preciso informar que isso gera penalidades ao usuario (vide URL dos termos)."
        }
      />
    </>
  );
};
