import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";

import { useAuth } from "../../../contexts/auth.context";
import { useProviderDetailsQuery } from "../../../hooks/queries/useProviderDetailsQuery";
import { useScheduleAppointment } from "../../../hooks/queries/useScheduleAppointment";
import { BookingSkeleton } from "../../Organisms/BookingSkeleton";
import { BookingError } from "../../Organisms/BookingError";
import { ProviderReviews } from "../../Organisms/ProviderReviews";
import { appointmentObjectives } from "../../../utils/appointmentObjectives";
import { BookingTemplate } from "../../Templates/BookingTemplate";
import { BookingHeader } from "../../Organisms/BookingHeader";
import { useDisclosure } from "@nextui-org/react";
import { BookingModal } from "../../Organisms/BookingModal";

export const ProviderBooking = () => {
  const { providerId } = useParams();
  const { currentUser } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [formData, setFormData] = useState(null);

  const handleFormDataChange = useCallback((newFormData: any) => {
    setFormData(newFormData);
  }, []);

  const { data: providerDetails, isLoading: isLoadingProvider } =
    useProviderDetailsQuery(providerId);

  const scheduleAppointment = useScheduleAppointment();

  if (isLoadingProvider) return <BookingSkeleton />;
  if (!providerDetails) return <BookingError />;

  return (
    <BookingTemplate
      title="Agendar Consulta"
      header={
        <BookingHeader
          provider={providerDetails}
          acceptedPaymentMethods={providerDetails.acceptedPaymentMethods}
          contactInfo={{
            phoneNumber: providerDetails.relatedClinic?.phoneNumber as string,
            name: providerDetails.relatedClinic?.name as string,
            address: providerDetails.relatedClinic?.address as string,
            timezone: providerDetails.timezone,
          }}
          userTimezone={currentUser.timezone}
          onOpen={onOpen}
        />
      }
    >
      <BookingModal
        isOpen={isOpen}
        onClose={onClose}
        isClinicBooking={false}
        providerDetails={providerDetails}
        currentUser={currentUser}
        scheduleAppointment={scheduleAppointment}
        appointmentObjectives={appointmentObjectives}
        formData={formData}
        onFormDataChange={handleFormDataChange}
      />
      <ProviderReviews provider={providerDetails} />
    </BookingTemplate>
  );
};
