import React from "react";
import { PreferenceLabel } from "../../Atoms/PreferenceLabel";
import { Switch } from "@nextui-org/react";

interface NotificationPreferenceProps {
  isNotificationEnabled: boolean;
  handleNotificationToggle: () => void;
}

export const NotificationPreference: React.FC<NotificationPreferenceProps> = ({
  isNotificationEnabled,
  handleNotificationToggle,
}) => (
  <div className="flex flex-col gap-2">
    <PreferenceLabel icon="bell">Notificações</PreferenceLabel>
    <div className="w-full flex justify-between items-center">
      <span className="text-default-900 font-medium px-1">
        Permitir Notificações
      </span>
      <Switch
        color="secondary"
        isSelected={isNotificationEnabled}
        onValueChange={handleNotificationToggle}
      />
    </div>
  </div>
);
