import { Avatar } from "@nextui-org/react";
import React from "react";
import { VerificationIcon } from "../../Molecules/VerificationIcon";

interface ProfileHeaderProps {
  name: string;
  photoURL: string;
  displayName: string;
  email: string;
  isEmailVerified: boolean;
  isPhoneVerified: boolean;
}

export const ProfileHeader: React.FC<ProfileHeaderProps> = ({
  name,
  photoURL,
  displayName,
  email,
  isEmailVerified,
  isPhoneVerified,
}) => (
  <div className="flex flex-col justify-center items-center gap-2">
    <Avatar name={name} src={photoURL} className="w-16 h-16" isBordered />
    <h5 className="flex gap-1 justify-center items-center">
      {displayName || email}
      <VerificationIcon
        isEmailVerified={isEmailVerified}
        isPhoneVerified={isPhoneVerified}
      />
    </h5>
  </div>
);
