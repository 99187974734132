import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  useEffect,
} from "react";
import { RecaptchaVerifier, ApplicationVerifier } from "firebase/auth";
import { auth } from "../services/firebase/firebase";

interface RecaptchaContextProps {
  executeRecaptcha: () => Promise<string>;
  recaptchaVerifier: ApplicationVerifier | null;
}

const RecaptchaContext = createContext<RecaptchaContextProps>({
  executeRecaptcha: async () => "",
  recaptchaVerifier: null,
});

export const RecaptchaProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [recaptchaVerifier, setRecaptchaVerifier] =
    useState<RecaptchaVerifier | null>(null);

  useEffect(() => {
    const verifier = new RecaptchaVerifier(auth, "recaptcha-container", {
      size: "invisible",
    });

    setRecaptchaVerifier(verifier);

    return () => {
      verifier.clear();
    };
  }, []);

  const executeRecaptcha = useCallback(async () => {
    if (!recaptchaVerifier) {
      throw new Error("reCAPTCHA not initialized");
    }
    return await recaptchaVerifier.verify();
  }, [recaptchaVerifier]);

  return (
    <RecaptchaContext.Provider value={{ executeRecaptcha, recaptchaVerifier }}>
      <div id="recaptcha-container"></div>
      {children}
    </RecaptchaContext.Provider>
  );
};

export const useRecaptcha = () => useContext(RecaptchaContext);
