import React, { useEffect, useState } from "react";

import { useDisclosure } from "@nextui-org/react";
import { sendEmailVerification, User } from "firebase/auth";
import { toast } from "react-toastify";
import { useAuth } from "../../../contexts/auth.context";
import { auth } from "../../../services/firebase/firebase";
import { ProfileTemplate } from "../../Templates/ProfileTemplate";
import { PhoneVerificationModal } from "../../Organisms/PhoneVerificationModal";
import { useProviderSearch } from "../../../hooks/useProviderSearch";
import { useNotificationPermission } from "../../../hooks/useNotificationPrompt";

export const Profile: React.FC = () => {
  const { handleSignOut, currentUser, updateUser, updateUserLoading } =
    useAuth();

  const {
    cityItems,
    handleCityChange,
    handleStateChange,
    isLoadingCities,
    isLoadingStates,
    isManualLocationMutationLoading,
    selectedCity,
    selectedState,
    stateItems,
    handleManualLocationUpdate,
  } = useProviderSearch();

  const [timezone, setTimezone] = useState(
    currentUser.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [name, setName] = useState<string>(currentUser?.name || "");
  const [isEmailVerificationLoading, setIsEmailVerificationLoading] =
    useState(false);

  const { permissionState, requestPermission, checkPermission, isPWA } =
    useNotificationPermission();

  const [isNotificationEnabled, setIsNotificationEnabled] = useState(false);

  useEffect(() => {
    setIsNotificationEnabled(permissionState === "granted");
  }, [permissionState]);

  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const handleEmailVerification = async () => {
    setIsEmailVerificationLoading(true);
    try {
      await sendEmailVerification(auth.currentUser!);
      toast.success("Email de verificação enviado!");
    } catch (error: any) {
      console.error(error);
      toast.error("Erro ao enviar email de verificação. Tente novamente.");
    } finally {
      setIsEmailVerificationLoading(false);
    }
  };

  const handleTimezoneChange = (newTimezone: string) => {
    setTimezone(newTimezone);
  };

  const handleLocationUpdate = () => {
    if (selectedState && selectedCity) {
      try {
        handleManualLocationUpdate(selectedState, selectedCity);
        toast.success("Localização atualizada com sucesso!");
      } catch (error: any) {
        toast.error("Erro: " + error);
      }
    }
  };

  const handleNotificationToggle = async () => {
    if (isNotificationEnabled) {
      toast.info(
        `Para desativar as notificações, ajuste as configurações do seu ${
          isPWA ? "app" : "navegador"
        }.`
      );
    } else {
      const newPermission = await requestPermission();
      if (newPermission === "granted") {
        setIsNotificationEnabled(true);
        toast.success("Notificações ativadas com sucesso!");
      } else {
        toast.info(
          `Falha ao ativar as notificações. Verifique as configurações do seu ${
            isPWA ? "app" : "navegador"
          }.`
        );
      }
    }
    checkPermission();
  };

  return (
    <>
      <ProfileTemplate
        user={auth.currentUser as User}
        currentUser={currentUser}
        name={name}
        setName={setName}
        updateUser={updateUser}
        isUpdateUserLoading={updateUserLoading}
        handleEmailVerification={handleEmailVerification}
        isEmailVerificationLoading={isEmailVerificationLoading}
        onOpenPhoneVerification={onOpen}
        handleSignOut={handleSignOut}
        stateItems={stateItems}
        cityItems={cityItems}
        selectedCity={selectedCity}
        selectedState={selectedState}
        timezone={currentUser.timezone}
        handleCityChange={handleCityChange}
        handleLocationUpdate={handleLocationUpdate}
        handleNotificationToggle={handleNotificationToggle}
        handleStateChange={handleStateChange}
        handleTimezoneChange={handleTimezoneChange}
        isLoadingCities={isLoadingCities}
        isLoadingStates={isLoadingStates}
        isManualLocationMutationLoading={isManualLocationMutationLoading}
        isNotificationEnabled={isNotificationEnabled}
        isTimezoneDisabled={timezone === currentUser.timezone}
      />

      <PhoneVerificationModal isOpen={isOpen} onOpenChange={onOpenChange} />
    </>
  );
};
