import { Route, Routes } from "react-router-dom";

import { SignIn } from "../components/Pages/SignIn";
import { SignUp } from "../components/Pages/SignUp";
import { ResetPassword } from "../components/Pages/ResetPassword";

export const AuthRoutes = () => {
  return (
    <Routes>
      <Route index path="/" element={<SignIn />} />
      <Route index path="*" element={<SignIn />} />
      <Route path="/login" element={<SignIn />} />
      <Route path="/sign-up" element={<SignUp />} />
      <Route path="/reset-password" element={<ResetPassword />} />
    </Routes>
  );
};
