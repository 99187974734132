import { BadgeInfo } from "lucide-react";
import { AppointmentStatus } from "../../../models/Appointment";
import { getColorByStatusCode } from "../../../utils/getColorByStatusCode";
import { StatusChip } from "../../Atoms/StatusChip";

export const AppointmentStatusBox = ({
  date,
  time,
  status,
  description,
}: {
  date: string;
  time: string;
  status: AppointmentStatus;
  description: string;
}) => {
  const { bg, text } = getColorByStatusCode(status);
  return (
    <div
      className={`${bg} flex justify-between items-start w-full rounded-xl md:p-2 p-1 flex-wrap`}
    >
      <div className="flex flex-col justify-center items-start p-1">
        <h2 className={`${text} text-3xl font-semibold`}>{date}</h2>
        <span className={`${text} text-md font-semibold ml-0.5`}>{time}</span>
      </div>
      <StatusChip
        startContent={<BadgeInfo className="w-4" />}
        status={status}
        description={description}
      />
    </div>
  );
};
