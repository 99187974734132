import React from "react";
import { Avatar, Button } from "@nextui-org/react";
import { CircleX, Stethoscope, ClipboardPlus } from "lucide-react";
import { motion } from "framer-motion";
import { MedicalService } from "../../../models/MedicalService";

interface EmptyResultCardProps {
  medicalService: MedicalService;
  onPress: () => void;
}

export const EmptyResultCard: React.FC<EmptyResultCardProps> = ({
  medicalService,
  onPress,
}) => {
  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        when: "beforeChildren",
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 10 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <motion.div
      className="flex flex-col items-center gap-4 bg-default-200 rounded-large border border-default-300 p-2 min-w-80 max-md:w-full"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <motion.div variants={itemVariants}>
        <Avatar
          size="lg"
          icon={
            medicalService.type === "exam" ? (
              <ClipboardPlus className="w-6 h-6" />
            ) : (
              <Stethoscope className="w-6 h-6" />
            )
          }
          classNames={{
            base:
              medicalService.type !== "exam"
                ? "bg-primary-50"
                : "bg-secondary-50",
            icon:
              medicalService.type !== "exam"
                ? "text-primary-600"
                : "text-secondary-600",
          }}
        />
      </motion.div>

      <motion.div
        className="flex flex-col items-center justify-center gap-2"
        variants={itemVariants}
      >
        <h3 className="text-2xl font-semibold leading-none text-center">
          Nenhum profissional encontrado
        </h3>
        <p className="text-center">
          {medicalService.type === "specialty"
            ? `Desculpe pelo transtorno, mas nenhum profissional de 
        ${medicalService.id} está disponível na sua região`
            : `Desculpe pelo transtorno, mas nenhuma clínica ou profissional que oferece
          ${medicalService.id} está disponível na sua região`}
        </p>
      </motion.div>

      <motion.div variants={itemVariants} className="w-full">
        <Button
          type="button"
          radius="md"
          variant="flat"
          color="primary"
          className="font-semibold w-full"
          endContent={<CircleX className="w-4" />}
          onPress={onPress}
        >
          Limpar pesquisa
        </Button>
      </motion.div>
    </motion.div>
  );
};
