import { Avatar } from "@nextui-org/react";
import { ProviderInformation } from "../../../models/ProviderInformation";
import { Clinic } from "../../../models/Clinic";

export const EntityInfo = ({
  provider,
  clinic,
  titleClassName,
  descriptionClassName,
  avatarClassName,
}: {
  provider?: ProviderInformation;
  clinic?: Clinic;
  titleClassName?: string;
  descriptionClassName?: string;
  avatarClassName?: string;
}) => (
  <div className="flex gap-2">
    <Avatar
      src={
        clinic ? clinic.imageUrl || undefined : provider?.imageUrl || undefined
      }
      className={avatarClassName}
      radius="md"
    />
    <div className="flex flex-col py-0.5">
      <span
        className={titleClassName || "text-sm font-medium text-default-900"}
      >
        {clinic ? clinic.name : provider?.name}
      </span>
      <span
        className={
          descriptionClassName || "text-sm font-medium text-default-400"
        }
      >
        {clinic ? clinic.cnpj : provider?.crmCode}
      </span>
    </div>
  </div>
);
