import React from "react";
import { Tab, Tabs } from "@nextui-org/react";
import { Award, BadgeCheck, BadgeInfo, BadgeMinus } from "lucide-react";
import { AppointmentStatus } from "../../../models/Appointment";
import { statusToTabMap } from "../../../utils/statusTabMapping";
import { AnimatePresence, motion } from "framer-motion";
import { useSwipeable } from "react-swipeable";

interface AppointmentTabsProps {
  selectedStatus: AppointmentStatus;
  onStatusChange: (status: AppointmentStatus) => void;
}

const statusList = Object.keys(statusToTabMap) as AppointmentStatus[];

export const AppointmentTabs: React.FC<AppointmentTabsProps> = ({
  selectedStatus,
  onStatusChange,
}) => {
  const handlers = useSwipeable({
    onSwipedLeft: () => handleSwipeDirection("left"),
    onSwipedRight: () => handleSwipeDirection("right"),
  });

  const handleSwipeDirection = (direction: "left" | "right") => {
    const currentIndex = statusList.indexOf(selectedStatus);
    if (direction === "left" && currentIndex < statusList.length - 1) {
      onStatusChange(statusList[currentIndex + 1]);
    } else if (direction === "right" && currentIndex > 0) {
      onStatusChange(statusList[currentIndex - 1]);
    }
  };

  return (
    <div {...handlers}>
      <Tabs
        key="MyAppointmentsTab"
        variant="solid"
        aria-label="Seleção de Agendamentos"
        color="primary"
        radius="md"
        size="lg"
        classNames={{
          tabList: "bg-default-300/70",
          tabContent: "text-default-900",
        }}
        selectedKey={statusToTabMap[selectedStatus]}
        onSelectionChange={(key) =>
          onStatusChange(
            Object.keys(statusToTabMap).find(
              (k) => statusToTabMap[k as AppointmentStatus] === key
            ) as AppointmentStatus
          )
        }
      >
        {Object.entries(statusToTabMap).map(([status, key]) => (
          <Tab
            key={key}
            title={
              <div className="flex items-center gap-1">
                {status === AppointmentStatus.WAITING_PROVIDER && <BadgeInfo />}
                {status === AppointmentStatus.CONFIRMED && <BadgeCheck />}
                {status === AppointmentStatus.COMPLETED && <Award />}
                {status === AppointmentStatus.CANCELLED && <BadgeMinus />}
                <AnimatePresence initial={false}>
                  {(selectedStatus === status || window.innerWidth >= 768) && (
                    <motion.span
                      key={status}
                      initial={{ opacity: 0, width: 0 }}
                      animate={{ opacity: 1, width: "auto" }}
                      exit={{ opacity: 0, width: 0 }}
                      transition={{ duration: 0.4, easings: ["easeInOut"] }}
                      className="inline-block overflow-hidden"
                    >
                      {status === AppointmentStatus.WAITING_PROVIDER &&
                        "Pendentes"}
                      {status === AppointmentStatus.CONFIRMED && "Confirmados"}
                      {status === AppointmentStatus.COMPLETED && "Concluídos"}
                      {status === AppointmentStatus.CANCELLED && "Cancelados"}
                    </motion.span>
                  )}
                </AnimatePresence>
              </div>
            }
            className="p-1"
          />
        ))}
      </Tabs>
    </div>
  );
};
