import { Button } from "@nextui-org/react";
import { ClipboardPlus, Stethoscope, Undo2 } from "lucide-react";
import { useNavigate } from "react-router-dom";

export const AppointmentHeader = ({
  route,
  type,
}: {
  route: string;
  type: "exam" | "appointment";
}) => {
  const navigate = useNavigate();

  return (
    <div className="flex w-full justify-start items-stretch gap-2">
      <Button
        href="/appointments"
        color="warning"
        variant="flat"
        size="lg"
        radius="lg"
        className="min-w-max aspect-square p-2 bg-warning-50 text-warning"
        onPress={() => navigate(route)}
      >
        <Undo2 aria-label="Voltar" />
      </Button>
      <div
        className={`flex items-center justify-center gap-2 px-4 rounded-large ${
          type === "appointment" ? "bg-primary-50" : "bg-secondary-50"
        }`}
      >
        <h1
          className={`text-2xl font-medium ${
            type === "appointment" ? "text-primary-900" : "text-secondary-900"
          }`}
        >
          {type === "appointment" ? "Consulta" : "Exame"}
        </h1>
        {type === "appointment" ? (
          <Stethoscope className="w-6 h-6 text-primary-500" />
        ) : (
          <ClipboardPlus className="w-6 h-6 text-secondary-600" />
        )}
      </div>
    </div>
  );
};
