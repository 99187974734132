export const AppointmentInfo = ({
  icon,
  text,
  className,
}: {
  icon: JSX.Element;
  text: string;
  className?: string;
}) => {
  return (
    <span className={className ? className : "flex gap-1"}>
      {icon}
      {text}
    </span>
  );
};
