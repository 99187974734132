import { Button } from "@nextui-org/react";
import { Undo2 } from "lucide-react";
import { useNavigate } from "react-router-dom";

export const Return = ({ route, title }: { route: string; title: string }) => {
  const navigate = useNavigate();

  return (
    <div className="flex w-full justify-start items-center gap-2">
      <Button
        href="/appointments"
        color="warning"
        variant="flat"
        size="md"
        radius="lg"
        className="min-w-max p-2 bg-warning-50 text-warning"
        onPress={() => navigate(route)}
      >
        <Undo2 aria-label="Voltar" />
      </Button>
      <h1 className="text-2xl font-medium">{title}</h1>
    </div>
  );
};
