import { Tabs, Tab } from "@nextui-org/react";
import {
  House,
  CalendarFold,
  UserRound,
  Hospital,
  ClipboardPlus,
} from "lucide-react";
import { useAuth } from "../../../contexts/auth.context";
import { useLocation } from "react-router-dom";

interface NavOption {
  key: string;
  role: string[];
  name: string;
  icon: React.ReactNode;
  path: string;
}

const navOptions: NavOption[] = [
  {
    key: "home",
    role: ["customer"],
    name: "Início",
    icon: <House className="w-7 h-7" />,
    path: "/",
  },
  {
    key: "providerHome",
    role: ["provider"],
    name: "Agendamentos",
    icon: <House className="w-7 h-7" />,
    path: "/appointments",
  },
  {
    key: "appointments",
    role: ["customer"],
    name: "Agendamentos",
    icon: <CalendarFold className="w-7 h-7" />,
    path: "/appointments",
  },
  {
    key: "adminClinics",
    role: ["admin"],
    name: "Clínicas",
    icon: <Hospital className="w-7 h-7" />,
    path: "/admin/clinics",
  },
  {
    key: "adminProviders",
    role: ["admin"],
    name: "Profissionais",
    icon: <ClipboardPlus className="w-7 h-7" />,
    path: "/admin/providers",
  },
  {
    key: "userProfile",
    role: ["customer", "provider", "admin"],
    name: "Perfil",
    icon: <UserRound className="w-7 h-7" />,
    path: "/profile",
  },
];

export const Navbar: React.FC = () => {
  const { currentUser } = useAuth();
  const location = useLocation();

  const navbarRoutes = [
    "/",
    "/appointments",
    "/profile",
    "/provider",
    "/admin/clinics",
    "/admin/providers",
  ];

  const shouldDisplayNavbar = navbarRoutes.includes(location.pathname);

  if (!shouldDisplayNavbar) {
    return null;
  }

  const filteredOptions = navOptions.filter((option) =>
    option.role.includes(currentUser?.role || "")
  );

  return (
    <Tabs
      key="Navbar"
      variant="bordered"
      aria-label="Telas"
      color="secondary"
      radius="full"
      size="lg"
      selectedKey={
        filteredOptions.find((option) => option.path === location.pathname)?.key
      }
      className="fixed bottom-5 left-1/2 -translate-x-1/2 z-50"
      classNames={{
        tabList: "backdrop-blur-md backdrop-saturate-200 bg-default-100/50",
        tab: "py-5",
      }}
    >
      {filteredOptions.map((option) => (
        <Tab
          key={option.key}
          title={
            <div className="flex items-center space-x-2">
              {option.icon}
              <span className="max-md:hidden">{option.name}</span>
            </div>
          }
          href={option.path}
          className="font-medium text-secondary-900"
        />
      ))}
    </Tabs>
  );
};
