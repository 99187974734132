import { useQuery } from "@tanstack/react-query";
import { getDocs, query, collection, where } from "firebase/firestore";
import { fireStoreDb } from "../../services/firebase/firebase";
import { Clinic } from "../../models/Clinic";

const fetchClinicsByIds = async (clinicIds: string[]): Promise<Clinic[]> => {
  if (clinicIds.length === 0) return [];

  const clinicsQuery = query(
    collection(fireStoreDb, "clinics"),
    where("id", "in", clinicIds)
  );

  const querySnapshot = await getDocs(clinicsQuery);

  const clinics = querySnapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  })) as Clinic[];

  return clinics;
};

export const useClinicsQuery = (clinicIds: string[]) => {
  return useQuery<Clinic[], Error>({
    queryKey: ["clinics", clinicIds],
    queryFn: () => fetchClinicsByIds(clinicIds),
    enabled: clinicIds.length > 0,
  });
};
