import React, { useState, useMemo, useEffect } from "react";
import {
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
} from "@nextui-org/react";
import { ChevronLeft, ChevronRight, Info } from "lucide-react";
import { Dayjs } from "dayjs";
import { DateUtils } from "../../../utils/dateUtils";
import { motion, AnimatePresence } from "framer-motion";

export const AppointmentScheduler: React.FC<{
  availableTimes: string[];
  onTimeSelection: (day: Dayjs, time: string) => void;
  selectedOptions: string[];
  isTimeDisabled: (day: Dayjs, time: string) => boolean;
  isDayDisabled: (day: Dayjs) => boolean;
  providerTimezone: string;
  userTimezone: string;
  medicalServiceDuration?: number;
  isExam: boolean;
}> = ({
  availableTimes,
  onTimeSelection,
  selectedOptions,
  isTimeDisabled,
  isDayDisabled,
  providerTimezone,
  userTimezone,
  medicalServiceDuration,
  isExam,
}) => {
  const today = DateUtils.now(providerTimezone).startOf("day");
  const [currentWeekStart, setCurrentWeekStart] = useState(today);
  const [selectedDay, setSelectedDay] = useState<Dayjs | null>(null);
  const [showAllTimes, setShowAllTimes] = useState(false);
  const [visibleDays, setVisibleDays] = useState(7);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setIsMobile(width < 640);
      if (width < 640) setVisibleDays(4);
      else if (width < 768) setVisibleDays(5);
      else setVisibleDays(7);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const findFirstEnabledDay = (startDay: Dayjs): Dayjs => {
    let currentDay = startDay;
    while (isDayDisabled(currentDay)) {
      currentDay = currentDay.add(1, "day");
    }
    return currentDay;
  };

  useEffect(() => {
    const firstEnabledDay = findFirstEnabledDay(today);
    setSelectedDay(firstEnabledDay);
    setCurrentWeekStart(firstEnabledDay.startOf("week"));
  }, []);

  const weekDays = useMemo(() => {
    return Array.from({ length: visibleDays }, (_, i) =>
      currentWeekStart.add(i, "day")
    );
  }, [currentWeekStart, visibleDays]);

  const isSlotDisabled = (day: Dayjs, time: string) => {
    const startTime = day
      .tz(providerTimezone)
      .hour(parseInt(time.split(":")[0]))
      .minute(parseInt(time.split(":")[1]));
    const endTime = startTime.add(medicalServiceDuration || 1, "minute");

    for (
      let currentTime = startTime;
      currentTime.isBefore(endTime);
      currentTime = currentTime.add(medicalServiceDuration || 1, "minute")
    ) {
      if (isTimeDisabled(currentTime, currentTime.format("HH:mm"))) {
        return true;
      }
    }

    return false;
  };

  const handlePrevWeek = () => {
    const prevWeek = currentWeekStart.subtract(visibleDays, "day");
    setCurrentWeekStart(prevWeek);
    if (selectedDay && selectedDay.isBefore(prevWeek)) {
      setSelectedDay(findFirstEnabledDay(prevWeek));
    }
  };

  const handleNextWeek = () => {
    const nextWeek = currentWeekStart.add(visibleDays, "day");
    setCurrentWeekStart(nextWeek);
    if (selectedDay && selectedDay.isBefore(nextWeek)) {
      setSelectedDay(findFirstEnabledDay(nextWeek));
    }
  };

  const handleDaySelection = (day: Dayjs) => {
    if (!isDayDisabled(day)) {
      setSelectedDay(day);
    }
  };

  const toggleShowAllTimes = () => {
    setShowAllTimes(!showAllTimes);
  };

  const displayedTimes = showAllTimes
    ? availableTimes
    : availableTimes.slice(0, 8);

  const InfoComponent = () => (
    <div className="p-2">
      <p>Os horários são exibidos no fuso-horário do prestador</p>
    </div>
  );

  return (
    <motion.div
      className="flex flex-col w-full gap-2 bg-white rounded-large"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="w-full bg-default-100 rounded-medium p-2 flex justify-between items-center">
        <h2 className="text-default-700 font-semibold text-sm">
          {isExam ? "Data do Exame" : "Data do Agendamento"}
        </h2>
        {isMobile ? (
          <Popover placement="bottom-end" showArrow>
            <PopoverTrigger>
              <Info className="w-4 h-4 text-default-400 cursor-help" />
            </PopoverTrigger>
            <PopoverContent>
              <InfoComponent />
            </PopoverContent>
          </Popover>
        ) : (
          <Tooltip content={<InfoComponent />}>
            <Info className="w-4 h-4 text-default-400 cursor-help" />
          </Tooltip>
        )}
      </div>
      <div className="flex items-center gap-1 w-full px-0.5">
        <Button
          isIconOnly
          size="sm"
          onPress={handlePrevWeek}
          isDisabled={
            currentWeekStart.isSame(today, "day") ||
            currentWeekStart.isBefore(today)
          }
          className="bg-default-300"
        >
          <ChevronLeft className="w-4 h-4 text-default-900" />
        </Button>
        <div className="flex gap-1 flex-grow">
          <AnimatePresence mode="popLayout">
            {weekDays.map((day) => (
              <motion.div
                key={day.format("YYYY-MM-DD")}
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.8 }}
                transition={{ duration: 0.2 }}
                className="w-full"
              >
                <Button
                  variant={
                    day.isSame(selectedDay, "day") ? "solid" : "bordered"
                  }
                  radius="lg"
                  color={
                    day.isBefore(today) || isDayDisabled(day)
                      ? "default"
                      : "warning"
                  }
                  className={`flex flex-col py-2 max-md:px-2 min-w-[40px] w-full gap-1 h-auto border-2 ${
                    day.isBefore(today) || isDayDisabled(day)
                      ? "border-default"
                      : "border-warning"
                  }`}
                  onPress={() => handleDaySelection(day)}
                  isDisabled={day.isBefore(today) || isDayDisabled(day)}
                >
                  <span className="text-xs">
                    {
                      ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"][
                        day.day()
                      ]
                    }
                  </span>
                  <span className="text-lg font-bold">{day.format("D")}</span>
                </Button>
              </motion.div>
            ))}
          </AnimatePresence>
        </div>
        <Button
          isIconOnly
          size="sm"
          onPress={handleNextWeek}
          className="bg-default-300"
        >
          <ChevronRight className="w-4 h-4 text-default-900" />
        </Button>
      </div>

      <div className="w-full bg-default-100 rounded-medium p-2">
        <h2 className="text-default-700 font-semibold text-sm">
          Sugira até 3 Horários
        </h2>
      </div>

      <motion.div
        className="grid gap-2 max-md:grid-cols-4 grid-cols-5"
        initial="hidden"
        animate="visible"
        variants={{
          visible: { transition: { staggerChildren: 0.1 } },
        }}
      >
        {displayedTimes.map((time) => {
          if (!selectedDay) return null;
          const providerDateTime = selectedDay
            .tz(providerTimezone)
            .hour(parseInt(time.split(":")[0]))
            .minute(parseInt(time.split(":")[1]));
          const userDateTime = providerDateTime.tz(userTimezone);

          const isSelected = selectedOptions.some((option) => {
            const optionDate = DateUtils.fromDBFormat(option, providerTimezone);
            return optionDate && optionDate.isSame(providerDateTime);
          });

          const isDisabled = isSlotDisabled(selectedDay, time);

          return (
            <Tooltip
              key={`${selectedDay.format("YYYY-MM-DD")}-${time}`}
              content={`Seu horário local: ${userDateTime.format("HH:mm")}`}
              placement="bottom"
            >
              <motion.div
                variants={{
                  hidden: { opacity: 0, y: 20 },
                  visible: { opacity: 1, y: 0 },
                }}
              >
                <Button
                  variant={isSelected ? "solid" : "bordered"}
                  color={isDisabled ? "default" : "warning"}
                  isDisabled={isDisabled}
                  onPress={() => onTimeSelection(selectedDay, time)}
                  className="w-full min-w-min"
                >
                  {time}
                </Button>
              </motion.div>
            </Tooltip>
          );
        })}
      </motion.div>

      {availableTimes.length > 8 && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3 }}
        >
          <Button
            variant="bordered"
            color="default"
            onPress={toggleShowAllTimes}
            className="w-full"
          >
            {showAllTimes ? "Mostrar Menos Horários" : "Mostrar Mais Horários"}
          </Button>
        </motion.div>
      )}
    </motion.div>
  );
};
